import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import CaseStudyView from '../components/views/CaseStudyTestimonialView/CaseStudyView'

// Utils
import getCustomRobotsFromTags from '../utils/getCustomRobotsFromTags'

const CaseStudyViewTemplate = ({ data, location }) => {
  const tags = data.prismicCaseStudy.tags;
  const robots = getCustomRobotsFromTags(tags);

  return (
    <>
      <Seo
        title={data.prismicCaseStudy.data.title.text}
        customTitle={data.prismicCaseStudy.data.meta_title}
        description={data.prismicCaseStudy.data.meta_description.text || data.prismicCaseStudy.data.intro.text}
        image={data.prismicCaseStudy.data.social_image.url}
        slug={location.pathname}
        robots={robots}
      />
      <CaseStudyView
        data={data}
      />
    </>
  )
}

export default withPreview(CaseStudyViewTemplate)

export const PageQuery = graphql`
  query CaseStudyViewTemplateQuery (
    $uid: String!,
    $published: Date!,
  ) {
    prismicCaseStudy (
      uid: {
        eq: $uid
      }
    ) {
      ...CaseStudy
      tags
      data {
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
      }
    }
    moreCaseStudies: allPrismicCaseStudy (
      filter: {
        uid: {
          ne: $uid
        },
        data: {
          published: {
            lt: $published
          }
        }
      },
      sort: {
        order: DESC,
        fields: data___published
      },
      limit: 3
    ) {
      nodes {
        ...CaseStudy
      }
    }
  }
`
